import {i18n} from "@/main";
// import { graphic } from 'echarts';

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setPieChart(state, data) {
    state.totalStaff = data.totalStaff;
    state.dataPieChart = [
      {
        type: 'pie',
        radius: '85%',
        center: ['50%', '50%'],
        data: data.UnitStaffs ? data.UnitStaffs.map(e => ({value: e.total, name: e.unitName})) : [],
        label: {
          show: true,
          formatter: (name) => {
            return `${name.data.name} (${name.data.value})`;
          },
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ]
  },
  setLineChart(state, data) {
    const {t} = i18n.global;
    state.dataLineChart.xAxis = data.map(e => `${t('common.month')}${e.month}`);
    state.dataLineChart.series = [
      {
        name: t('attendance_rp_mn.working'),
        type: 'line',
       
        data: data.map(e => e.working),
        color: '#F2AE3E',
      },
      {
        name: t('attendance_rp_mn.new_staff'),
        type: 'line',
      
        data: data.map(e => e.newStaff),
        color: '#4C9008',
      },
      {
        name: t('attendance_rp_mn.quit_job'),
        type: 'line',
     
        data: data.map(e => e.quitJob),
        color: '#08B0D5',
      },
    ]
  },
  setListTable(state, data) {
    state.listLated = data.topLated;
    state.listExitEarly = data.topExitEarly;
  },
  setBarChartLated(state, data) {
    state.barChartLated.xAxis = data.lated.map((e) => e.columnName);
    state.barChartLated.series = [];
    const listUnit = data.lated.map(e => e.bucket ? e.bucket : []);
    const units = listUnit.map(e => e.map(ec => ec.unitName));
    const newListUnit = [...new Set(units.flat())];
    
    const newData = data.lated.map(e => e.bucket ? e.bucket.map((ec) => { return {key: e.columnName, unit: ec.unitName, count: ec.total}}) : []);
    const finalData = Object.groupBy(newData.flat(), ({unit}) => unit);
    let unitData = [];
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(finalData)) {
      unitData.push(value);
    }
    unitData.forEach((column) => {
      state.barChartLated.xAxis.forEach((item) => {
        let found = false;
        for (let i = 0; i < column.length; i ++) {
          if(column[i]['key'] === item) {
            found = true;
            break;
          }
        }
        if(!found) {
          column.push({key: item, unit: column[0].unit, count: 0});
        }
      });
    });
    unitData.forEach((item) => {
      item.sort((a, b) => {
        return (state.barChartLated.xAxis.indexOf(a.key)) - (state.barChartLated.xAxis.indexOf(b.key))
      })
    })
    state.barChartLated.series = newListUnit.map((name, sid) => {
      return {
        name,
        type: 'bar',
        stack: 'total',
        emphasis: {
          focus: 'series',
        },
        data: unitData[sid].map((e) => e.count),
        barWidth: 14,
      }
    });
  },
  setBarChartEarly(state, data) {
    state.barChartEarly.xAxis = data.exitEarly.map((e) => e.columnName);
    state.barChartEarly.series = [];
    const listUnit = data.lated.map(e => e.bucket ? e.bucket : []);
    const units = listUnit.map(e => e.map(ec => ec.unitName));
    const newListUnit = [...new Set(units.flat())];
    const newData = data.exitEarly.map(e => e.bucket ? e.bucket.map((ec) => { return {key: e.columnName, unit: ec.unitName, count: ec.total}}) : []);
    const finalData = Object.groupBy(newData.flat(), ({unit}) => unit);
    let unitData = [];
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(finalData)) {
      unitData.push(value);
    }
    unitData.forEach((column) => {
      state.barChartEarly.xAxis.forEach((item) => {
        let found = false;
        for (let i = 0; i < column.length; i ++) {
          if(column[i]['key'] === item) {
            found = true;
            break;
          }
        }
        if(!found) {
          column.push({key: item, unit: column[0].unit, count: 0});
        }
      });
    });
    unitData.forEach((item) => {
      item.sort((a, b) => {
        return (state.barChartEarly.xAxis.indexOf(a.key)) - (state.barChartEarly.xAxis.indexOf(b.key))
      })
    })
    state.barChartEarly.series = newListUnit.map((name, sid) => {
      return {
        name,
        type: 'bar',
        stack: 'total',
        emphasis: {
          focus: 'series',
        },
        data: unitData[sid].map((e) => e.count),
        barWidth: 14,
      }
    });
  }
};
