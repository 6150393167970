import store from '@/vuex/store';
import Cookies from 'js-cookie';

const guard = async (from) => {
  if (store.getters.allMenu.includes(from.path)) {
    return true;
  }
  return { path: '404' };
};

const adminRoutes = [
  {
    path: '',
    redirect: () => {
      return Cookies.get('menuPath') || '/home';
    },
  },
  {
    path: 'home',
    name: 'home',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/home-page/HomePage.vue'
      ),
  },
  {
    path: 'settings',
    name: 'settings',
    component: () =>
      import(
        /* webpackChunkName: "Settings" */ '@/view/pages/settings/Settings.vue'
      ),
    children: [
      {
        path: 'profile-settings',
        name: 'profile-settings',
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Profile.vue'
          ),
      },
      {
        path: 'profile-settings/profile',
        name: 'set-profile',
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Profile.vue'
          ),
      },
      {
        path: 'profile-settings/account',
        name: 'set-account',
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Account.vue'
          ),
      },
      {
        path: 'profile-settings/password',
        name: 'set-password',
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Password.vue'
          ),
      },
    ],
  },
  {
    path: 'system',
    name: 'system',
    children: [
      {
        path: 'menu',
        name: 'menu-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/system/menu/Menu.vue'
          ),
      },
      {
        path: 'role',
        name: 'role-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/system/role/Role.vue'
          ),
      },
      {
        path: 'user',
        name: 'user-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/user/User.vue'
          ),
      },
      {
        path: 'language',
        name: 'language',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/system/language/Language.vue'
          ),
      },
    ],
  },
  {
    path: 'profile-management',
    name: 'profile-management',
    children: [
      {
        path: '',
        name: 'list-employee',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/profile-management/list-employee.vue'
          ),
      },
      {
        path: 'create',
        name: 'profile-create',
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-management/form.vue'
          ),
      },
      {
        path: 'edit',
        name: 'profile-edit',
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-management/form.vue'
          ),
      },
      {
        path: 'view',
        name: 'profile-detail',
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-management/detail.vue'
          ),
      },
      {
        path: 'customers',
        name: 'list-customer',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/profile-management/list.vue'
          ),
      },
      {
        path: 'customers/view',
        name: 'customer-profile-detail',
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-management/detail.vue'
          ),
      },
      {
        path: 'customers/create',
        name: 'customer-profile-create',
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-management/form.vue'
          ),
      },
      {
        path: 'customers/edit',
        name: 'customer-profile-edit',
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-management/form.vue'
          ),
      },
    ],
  },
  {
    path: 'unit-management',
    name: 'unit-management',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/unit-management/list.vue'
      ),
  },
  {
    path: 'attendance-management',
    name: 'attendance-management',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/attendance-management/list.vue'
      ),
  },
  {
    path: 'time-management',
    name: 'time-management',
    children: [
      {
        path: '',
        name: 'list-time',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/time-management/list.vue'
          ),
      },
      {
        path: 'edit',
        name: 'time-edit',
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/time-management/edit.vue'
          ),
      },
      {
        path: 'create',
        name: 'time-create',
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/time-management/create.vue'
          ),
      },
    ],
  },

  {
    path: 'event-management',
    name: 'event-management',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/event-management/list.vue'
      ),
  },
  {
    path: 'fire-event',
    name: 'fire-event',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/fire-event/list.vue'
      ),
  },
  {
    path: 'fire-alert-config',
    name: 'fire-alert-config',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/fire-event/alert-config.vue'
      ),
  },
  {
    path: 'monitor-event',
    name: 'monitor-event',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/event-monitor/list.vue'
      ),
  },
  {
    path: 'monitor-statistic',
    name: 'monitor-statistic',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/monitor-statistics/index.vue'
      ),
  },
  {
    path: 'access-control',
    name: 'access-control',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/access-control/AccessControl.vue'
      ),
  },
  // {
  //   path: 'attendance-report',
  //   name: 'attendance-report',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "Manager"  */ '@/view/manager/attendance-report/AttendanceReport.vue'
  //     ),
  // },
  {
    path: 'register-in-out',
    name: 'register-in-out',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/register-in-out/RegisterInOut.vue'
      ),
  },
  {
    path: 'report',
    name: 'report',
    children: [
      {
        path: 'timekeeping',
        name: 'timekeeping',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/timekeeping/list.vue'
          ),
      },
    ],
  },
  {
    path: 'lane-control',
    name: 'lane-control',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/lane-control/list.vue'
      ),
  },
  {
    path: 'security-event',
    name: 'security-event',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/security-event/list.vue'
      ),
  },
  {
    path: 'area',
    name: 'area',
    beforeEnter: [guard],
    component: () =>
      import(/* webpackChunkName: "Manager"  */ '@/view/manager/area/Area.vue'),
  },
  {
    path: 'partner',
    name: 'partner',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/partner/Partner.vue'
      ),
  },
  {
    path: 'log',
    name: 'log',
    beforeEnter: [guard],
    component: () =>
      import(/* webpackChunkName: "Manager"  */ '@/view/manager/log/Log.vue'),
  },
  {
    path: 'ai-box',
    name: 'ai-box',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/ai-box/Ai-Box.vue'
      ),
  },
  {
    path: 'vms',
    name: 'vms',
    children: [
      {
        path: 'group',
        name: 'group',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/device/Group.vue'
          ),
      },
      {
        path: 'device',
        name: 'device',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/device/Device.vue'
          ),
      },
      {
        path: 'tracking',
        name: 'tracking',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/device/Tracking.vue'
          ),
      },
    ],
  },
  {
    path: 'intrusion-statistics',
    name: 'intrusion-statistics',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/intrusion-statistics/list.vue'
      ),
  },
  {
    path: 'open-door',
    name: 'open-door',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/open-door/list.vue'
      ),
  },
  {
    path: 'personal-statistics',
    name: 'personal-statistics',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/personal-statistics/PersonalStatistics.vue'
      ),
  },
  {
    path: 'attendance-report-management',
    name: 'attendance-report-management',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/attendance-report-management/AttendanceRpManagement.vue'
      ),
  },
  {
    path: 'leave-application',
    name: 'leave-application',
    children: [
      {
        path: 'list-approve',
        name: 'list-approve',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/leave-application-management/list-approve.vue'
          ),
      },
      {
        path: 'list-create',
        name: 'list-create',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/leave-application-management/list-create.vue'
          ),
      },
      {
        path: 'create',
        name: 'create-leave-application',
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/leave-application-management/form.vue'
          ),
      },
      {
        path: 'detail-approve',
        name: 'detail-approve',
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/leave-application-management/detail.vue'
          ),
      },
      {
        path: 'detail-create',
        name: 'detail-create',
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/leave-application-management/detail.vue'
          ),
      },
    ],
  },
  {
    path: 'staff-timekeeping',
    name: 'staff-timekeeping',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/staff-timekeeping/StaffTimekeeping.vue'
      ),
  },
  {
    path: 'alert-configuration',
    name: 'alert-configuration',
    children: [
      {
        path: 'list',
        name: 'list',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/alert-configuration/AlertConfiguration.vue'
          ),
      },
      {
        path: 'form',
        name: 'form',
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/alert-configuration/FormAlert.vue'
          ),
      },
      {
        path: 'detail',
        name: 'detail',
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/alert-configuration/FormDetails.vue'
          ),
      },
    ],
  },
  {
    path: 'access-registration-report',
    name: 'access-registration-report',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/access-registration-report/index.vue'
    ),
  },
  {
    path: 'holiday',
    name: 'holiday',
    children: [
      {
        path: '',
        name: 'list-holiday',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/holiday/index.vue'
          ),
      },
      {
        path: 'create',
        name: 'create-holiday',
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/holiday/form.vue'
          ),
      },
      {
        path: 'update',
        name: 'update-holiday',
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/holiday/form.vue'
          ),
      },
      {
        path: 'detail',
        name: 'detail-holiday',
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/holiday/detail.vue'
          ),
      },
    ],
  },
  {
    path: 'attendance-notification-config',
    name: 'attendance-notification-config',
    children: [
      {
        path: '',
        name: 'attendance-notify-list',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/attendance-notification-config/index.vue'
          ),
      },
      {
        path: 'edit',
        name: 'attendance-notify-edit',
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/attendance-notification-config/form.vue'
          ),
      },
    ]
  },
  {
    path: 'live-event',
    name: 'live-event',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/live-event/list.vue'
    ),
  }
];
export default adminRoutes;
