import {
  KEY_AI_FLOW,
  TYPE_AI_OBJECT,
  DEVICE_TYPE,
  dataDefault,
} from '@/util/common-constant';

export default {
  createNewFormListDevice(state, value) {
    const { data, type } = value;
    if (Array.isArray(data)) {
      const listDatanew = data.map((device) => {
        const listCamera = Array.isArray(device.cameraDTOs)
          ? device.cameraDTOs.map((camera) => {
              dataDefault.forEach((item) => {
                if (item.key === TYPE_AI_OBJECT.HUMAN) {
                  item.total = camera.humanCount || 0;
                  item.Ai.forEach((ai) => {
                    ai.active = camera[ai.key] > 0;
                    if (device.type === DEVICE_TYPE.BOX) {
                      ai.isDiff = camera.diffFeatures.includes(ai.type);
                    }
                  });
                } else if (item.key === TYPE_AI_OBJECT.VEHICLE) {
                  item.total = camera.vehicleCount || 0;
                  item.Ai.forEach((ai) => {
                    ai.active = camera[ai.key] > 0;
                    if (device.type === DEVICE_TYPE.BOX) {
                      ai.isDiff = camera.diffFeatures.includes(ai.type);
                    }
                  });
                } else if (item.key === TYPE_AI_OBJECT.FIRE) {
                  item.total = camera.fireCount || 0;
                  item.Ai.forEach((ai) => {
                    ai.active = camera[ai.key] > 0;
                    if (device.type === DEVICE_TYPE.BOX) {
                      ai.isDiff = camera.diffFeatures.includes(ai.type);
                    }
                  });
                }
              });
              return {
                ...camera,
                listContentAi: JSON.parse(JSON.stringify(dataDefault)),
              };
            })
          : device.cameras;
        dataDefault.forEach((item) => {
          const isHuman = item.key === TYPE_AI_OBJECT.HUMAN;
          const isVehicle = item.key === TYPE_AI_OBJECT.VEHICLE;
          const isFire = item.key === TYPE_AI_OBJECT.FIRE;
          const totalKey = isHuman
            ? KEY_AI_FLOW.humanCount
            : isVehicle
            ? KEY_AI_FLOW.vehicleCount
            : isFire
            ? KEY_AI_FLOW.fireCount
            : null;
          const groupAIs =
            device.type === DEVICE_TYPE.BOX ? device.features || [] : null;

          item.total = device[totalKey] || 0;
          item.Ai.forEach((ai) => {
            ai.active = groupAIs
              ? groupAIs.includes(ai.type)
              : device[ai.key] > 0;
          });
        });
        return {
          ...device,
          listContentAi: JSON.parse(JSON.stringify(dataDefault)),
          cameras: listCamera,
          type: type,
        };
      });
      state.listDevice = listDatanew;
      state.isLoading = false;
    }
  },
  createNewFormListDeviceGroup(state, data) {
    if (Array.isArray(data)) {
      const listDatanew = data.map((group) => {
        const listGroup = group?.childGroups.map((box) => {
          const listCamera = box.cameras.map((camera) => {
            return {
              ...camera,
              listContentAi: JSON.parse(JSON.stringify(dataDefault)),
              init: true,
            };
          });
          return {
            ...box,
            cameras: listCamera,
          };
        });
        const listCameras = group.cameras.map((camera) => {
          return {
            ...camera,
            listContentAi: JSON.parse(JSON.stringify(dataDefault)),
            init: true,
          };
        });
        return {
          ...group,
          listContentAi: JSON.parse(JSON.stringify(dataDefault)),
          childGroups: listGroup,
          cameras: listCameras,
          init: true,
        };
      });
      state.listGroupDevice = listDatanew;
    }
  },
};
