import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { checkResponse } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { i18n } from '@/main';
import { showConfirm } from '@/util/confirm';
import dayjs from 'dayjs';

const state = () => ({
  loading: false,
  api: ConstantAPI.fireAlert,
  alertInfo: null,
  listEvent: [],
  total: 0
});

const actions = {
  async updateConfig({ commit, state }, payload) {
    const { t } = i18n.global;
    try {
      if (await showConfirm(t('common.confirm_save'))) {
        commit('setLoading', true);
        const response = await DataService.callApi(
          state.api.UPDATE_CONFIG,
          payload,
          null
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('common.update_success', {
              name: t('common.config'),
            }),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async getInfo({ commit, state }) {
    let isSuccess = false;
    try {
      const response = await DataService.callApi(state.api.GET_CONFIG);
      checkResponse(
        response,
        (res) => {
          state.alertInfo = res;
          isSuccess = true;
        },
        () => {}
      );
      commit('setLoading', false);
      return isSuccess;
    } catch (err) {
      console.log(err);
    }
  },
  async getAll({ state }, params) {
    try {
      const response = await DataService.callApi(state.api.FIRE_EVENT, null, {
        ...params,
        page: params.page - 1,
        dateFrom:
          params && params.dateFrom
            ? dayjs(params.dateFrom).set('seconds', 0).toDate()
            : null,
        dateTo:
          params && params.dateTo
            ? dayjs(params.dateTo).set('seconds', 59).toDate()
            : null,
        cameraId: params && params.cameraId ? params.cameraId.join(',') : null
      });
      let success;
      checkResponse(response, () => {
        success = true;
        state.listEvent = response.data.content ? response.data.content : [];
        state.total = response.data.totalElements;
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
