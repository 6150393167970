import mutations from './mutations';
import { getField, updateField } from 'vuex-map-fields';
import { DataService } from '@/dataService/dataService';
import ConstantAPI from '@/config/ConstantAPI';
import { notification } from 'ant-design-vue';
import { i18n } from '@/main';
import { DEVICE_TYPE } from '@/util/common-constant';
const state = () => ({
  api: ConstantAPI.DEVICE,
  totalGroup: 0,
  listGroupCamera: [],
  listGroupDevice: [],
  listDevice: [],
  isShowEditGroup: false,
  paramsDevice: {
    type: DEVICE_TYPE.BOX,
    pageNumber: 0,
    pageSize: 10,
    detail: false,
  },
  paramsGroup: {
    userGroupIdAlts: null,
    pageNumber: 0,
    pageSize: 12,
    detail: false,
    activate: null,
    name: null,
  },
  isLoading: false,
});

const actions = {
  async applyAi({ state }, body) {
    const response = await DataService.callApi(
      state.api.APPLY_AI_GROUP,
      body,
      null
    );
    return response;
  },
  async getListDevice({ commit, state }, params) {
    state.paramsDevice = params;
    state.isLoading = true;
    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([, value]) => value !== null)
    );
    const queryString = new URLSearchParams(filteredParams).toString();
    try {
      const listData = [];
      const response = await DataService.get(
        `${state.api.GET_LIST_DEVICE.url}?${queryString}`
      );
      (response.content || []).map((item) => {
        listData.push(item);
      });
      commit('createNewFormListDevice', {
        data: listData,
        type: params.type,
      });
      state.totalDevice = response ? response.totalElements : 0;
    } catch (err) {
      console.log(err);
    }
  },
  async getListGroupDevice({ commit, state }, params) {
    state.paramsGroup = params;
    state.isLoading = true;
    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([, value]) => value !== null)
    );
    const queryString = new URLSearchParams(filteredParams).toString();
    try {
      const response = await DataService.get(
        `${state.api.GET_GROUPS_TREE.url}?${queryString}`
      );
      state.totalGroup = response ? response.totalElements : 0;
      commit('createNewFormListDeviceGroup', response ? response.content : []);
    } catch (err) {
      console.log(err);
    }
  },
  async getGroupCamera({ state }, params) {
    try {
      const response = await DataService.callApi(
        state.api.GET_GROUP_CAMERA,
        null,
        params
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  async getCamera({ state }, params) {
    try {
      const response = await DataService.callApi(
        state.api.GET_CAMERA,
        null,
        params
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  async activeGroup({ state }, params) {
    try {
      const response = await DataService.callApi(
        state.api.ACTIVE_GROUP,
        params,
        null
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  async handleEditGroup({ state }, params) {
    const { t } = i18n.global;
    try {
      const response = await DataService.callApi(
        state.api.ACTIVE_GROUP,
        params,
        null
      );
      notification.success({
        message: t('common.notification'),
        description: t('common.update_success', {
          name: t('device.group'),
        }),
        duration: 4,
      });
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  async deleteCameraInGroup({ state }, params) {
    try {
      const response = await DataService.callApi(
        state.api.DELETE_CAMERA_IN_GROUP,
        params,
        null
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  async deleteDoorInGroup({ state }, params) {
    try {
      const response = await DataService.callApi(
        state.api.DELETE_DOOR_IN_GROUP,
        params,
        null
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  async deleteBoxInGroup({ state }, params) {
    try {
      const response = await DataService.callApi(
        state.api.DELETE_BOX_IN_GROUP,
        params,
        null
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  handleShowEditGroup({ state }, value) {
    state.isShowEditGroup = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
