import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { getField, updateField } from 'vuex-map-fields';
import { DataService } from '@/dataService/dataService';
import { checkResponse } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { showConfirm } from '@/util/confirm';
import { i18n } from '@/main';

const state = () => ({
  list: [],
  loading: false,
  api: ConstantAPI.emailConfig,
  personalInfo: {},
  summaryInfo: {},
  data: null,
  key: '',
});

const actions = {
  async getPersonal({ state }) {
    const response = await DataService.callApi(
      state.api.GET_PERSONAL_CONFIG,
      null,
      null
    );
    checkResponse(response, () => {
      state.personalInfo = response.data;
    });
  },
  async updatePersonal({ state }, payload) {
    const { t } = i18n.global;
    try {
      if (await showConfirm(t('common.confirm_save'))) {
        const response = await DataService.callApi(
          state.api.UPDATE_PERSONAL_CONFIG,
          payload,
          null
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('common.update_success', {
              name: t('common.config'),
            }),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async getSummary({ state }) {
    const response = await DataService.callApi(
      state.api.GET_SUMMARY_CONFIG,
      null,
      null
    );
    checkResponse(response, () => {
      state.summaryInfo = response.data;
    });
  },
  async updateSummary({ state }, payload) {
    const { t } = i18n.global;
    try {
      if (await showConfirm(t('common.confirm_save'))) {
        const response = await DataService.callApi(
          state.api.UPDATE_SUMMARY_CONFIG,
          payload,
          null
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('common.update_success', {
              name: t('common.config'),
            }),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async updateData({ state }, payload) {
    const { t } = i18n.global;
    try {
      if (await showConfirm(t('common.confirm_save'))) {
        const response = await DataService.callApi(
          state.api.UPDATE,
          payload,
          null
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('common.update_success', {
              name: t('common.config'),
            }),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  setData({ state }, data) {
    state.key = data ? data.key : '';
    state.data = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
