import { createStore } from 'vuex';
import themeLayout from './modules/themeLayout/actionCreator';
import auth from './modules/authentication/axios/actionCreator';
import role from './modules/system/role/actionCreator';
import menu from './modules/system/menu/actionCreator';
import user from './modules/system/user/actionCreator';
import chartContent from './modules/chartContent/actionCreator';
import clip from './modules/clip/clip/actionCreator';
import category from './modules/category/actionCreator';
import reportNews from './modules/report/news/actionCreator';
import articles from './modules/articles/actionCreator';
import mediaFiles from './modules/mediafiles/actionCreator';
import procedure from './modules/procedure/actionCreator';
import legislation from './modules/legislation/actionCreator';
import appParam from './modules/appParam/actionCreator';
import topics from './modules/topics/actionCreator';
import introduce from './modules/introduce/actionCreator';
import banners from './modules/banners/actionCreator';
import qAndA from './modules/qAndA/actionCreator';
import document from './modules/document/actionCreator';
import recruitment from './modules/recruitment/actionCreator';
import contact from './modules/contact/actionCreator';
import caseStudy from './modules/case-study/actionCreator';
import unitManagement from './modules/unit-management/actionCreator';
import timeManagement from './modules/time-management/actionCreator';
import profileManagement from './modules/profile-management/actionCreator';
import workShift from './modules/work-shift/actionCreator';
import selectList from './modules/selectList/actionCreator';
import nationality from './modules/system/nationality/actionCreator';
import province from './modules/system/province/actionCreator';
import attendance from './modules/attendance/actionCreator';
import event from './modules/event/actionCreator';
import registerInOut from './modules/registerInOut/actionCreator';
import accessControl from './modules/access-control/actionCreator';
import dashboard from './modules/dashboard/actionCreator';
import building from './modules/building/actionCreator';
import notification from './modules/notification/actionCreator';
import partner from './modules/partner/actionCreator';
import homePage from './modules/home-page/actionCreator';
import openDoor from './modules/openDoor/actionCreator';
import area from './modules/area/actionCreator';
import log from './modules/log/actionCreator';
import aibox from './modules/ai-box/actionCreator';
import home from './modules/home/actionCreator';
import accessRegistrationReport from './modules/access-registration-report/actionCreator';
import alertConfiguration from './modules/alert-configuration/actionCreator';
import attendanceReportManagement from './modules/attendance-report-management/actionCreator';
import device from './modules/device/actionCreator';
import deviceGroup from './modules/device-group/actionCreator';
import leaveApplication from './modules/leave-application/actionCreator';
import monitorStatistic from './modules/monitor-statistics/actionCreator';
import holiday from './modules/holiday/actionCreator';
import attendanceNotifyConfig from './modules/attendance-notify-config/actionCreator';
import fireAlert from './modules/fire-alert/actionCreator';
export default createStore({
  modules: {
    themeLayout,
    auth,
    role,
    menu,
    user,
    chartContent,
    clip,
    category,
    reportNews,
    articles,
    procedure,
    legislation,
    appParam,
    introduce,
    topics,
    banners,
    qAndA,
    document,
    mediaFiles,
    recruitment,
    contact,
    caseStudy,
    unitManagement,
    timeManagement,
    profileManagement,
    workShift,
    selectList,
    nationality,
    province,
    attendance,
    event,
    registerInOut,
    accessControl,
    dashboard,
    building,
    notification,
    partner,
    homePage,
    openDoor,
    area,
    log,
    aibox,
    home,
    accessRegistrationReport,
    alertConfiguration,
    attendanceReportManagement,
    device,
    leaveApplication,
    monitorStatistic,
    holiday,
    attendanceNotifyConfig,
    deviceGroup,
    fireAlert
  },
});
