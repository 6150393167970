/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
import { JSEncrypt } from 'jsencrypt';
import { PUBLIC_KEY, SECRET } from '@/config/key';
import CryptoJS from 'crypto-js';
import { notification } from 'ant-design-vue';
import dayjs from 'dayjs';
import _ from 'lodash';
import { format, parseISO } from 'date-fns';
import { i18n } from '@/main';
import { STATISTICAL_OBJECT } from '@/util/common-constant';
import { DATE_TYPE } from './common-constant';
import { ref, onMounted, onBeforeUnmount } from 'vue';

const randomChar = (size) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < size) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

const ellipsis = (text, size) => {
  return `${text.split(' ').slice(0, size).join(' ')}...`;
};

const ellipsisText = (text, size) => {
  return `${text.slice(0, size)}...`;
};

const encryptData = (data, publicKey = PUBLIC_KEY) => {
  let encryptor = new JSEncrypt();
  encryptor.setPublicKey(publicKey);
  return encryptor.encrypt(data);
};

const hashData = (payload, secret = SECRET) => {
  return CryptoJS.SHA256(JSON.stringify(payload) + secret).toString();
};

const formatDate = (date, formatType) => {
  if (_.isNil(date)) return '';
  return format(Date.parse(date), formatType || 'dd/MM/yyyy HH:mm:ss');
};

const formatSource = (data) => {
  if (data.includes('http'))
    return `<a href=${data} target="_blank" class="text-blue-500">Xem chi tiết</a>`;
  return data;
};

const checkResponse = (
  response,
  success = () => {},
  fail = () => {
    const { t } = i18n.global;
    notification.error({
      message: t('common.notification'),
      description: response.message || t('common.error'),
      duration: 4,
    });
  }
) => {
  if (response.code === '200') {
    success(response.data);
  } else {
    fail(response.data);
  }
};

const checkResponseAsync = async (
  response,
  success = () => {},
  fail = () => {
    const { t } = i18n.global;
    notification.error({
      message: t('common.notification'),
      description: response.message || t('common.error'),
      duration: 4,
    });
  }
) => {
  if (response.code === '200') {
    await success(response.data);
  } else {
    await fail(response.data);
  }
};

const forEachNested = (parent, cb) => {
  if (!cb) return;
  cb(parent);
  if (parent.children && parent.children.length > 0) {
    parent.children.forEach((p) => forEachNested(p, cb));
  }
};

const greaterThanCurrent = (current) =>
  current && current > dayjs().endOf('day');

const lessThanCurrent = (current) =>
  current && current <= dayjs().endOf('day').subtract(1, 'days');

const getText = (value, array) => {
  const s = array.filter((s) => s.value === value);
  if (s && s.length) return s[0].label;
  return '';
};

export function checkIsAdmin(payload) {
  return payload === null ? true : false;
}

export function formatNumberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const censorNumber = (value) => {
  return value.substring(0, 3) + '****' + value.substring(7, value.length - 7);
};

const roundNumber = (number, digit = 2) => {
  if (_.isNil(number)) return null;
  return parseFloat(number.toFixed(digit));
};

const flatten = (array, field) => {
  return array.reduce((newArr, obj) => {
    if (field) {
      newArr.push(obj[field]);
    } else {
      newArr.push(obj);
    }
    if (obj.children) {
      return newArr.concat(flatten(obj.children, field));
    }
    return newArr;
  }, []);
};

const addToList = (list, data) => {
  return [
    ...list.slice(0, list.length - 1),
    data,
    ...list.slice(list.length - 1),
  ];
};

const addEdge = (list, source, target, updatable = true) => {
  const id = randomChar(6);
  list = addToList(list, {
    id: id,
    source: source,
    target: target,
    updatable: updatable,
  });
  return list;
};
const slugify = (text) => {
  const a =
    'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
  const b =
    'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');
  return text
    .toString()
    .toLowerCase()
    .replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a')
    .replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e')
    .replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i')
    .replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o')
    .replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u')
    .replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y')
    .replace(/đ/gi, 'd')
    .replace(/\s+/g, '-')
    .replace(p, (c) => b.charAt(a.indexOf(c)))
    .replace(/&/g, '-and-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
};
const convertDataFlow = (list, isEdit) => {
  let response = {
    nodes: [],
    connections: [],
  };

  response.nodes = list
    .filter((e) => e.isNode)
    .map((e) => ({
      ...e,
      id: isEdit ? e.idDb : null,
      code: e.id,
      name: e.label,
      position: JSON.stringify(e.position),
    }));
  response.connections = list
    .filter((e) => !e.isNode)
    .map((e) => ({ ...e, id: null, code: e.id }));
  console.log(response);
  return response;
};

const getTextCustom = (value, array, label = 'label', val = 'value') => {
  const s = array.filter((s) => s[val] === value);
  if (s && s.length) return s[0][label];
  return '';
};

export const trimStr = (data) => {
  return data ? data.trim().replace(/\s+/g, ' ') : '';
};

const trimValueObj = (obj) => {
  if (!obj) {
    return null;
  }
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'string' || obj[key] instanceof String) {
      obj[key] = trimStr(obj[key]);
    }
  });
  return obj;
};

const checkHasPermission = (permissions, value) => {
  return !permissions.includes(value) ? false : true;
};

const mapAndGetTotal = (obj) => {
  if (!obj) {
    return null;
  }
  let total = 0;
  Object.keys(obj).forEach((key) => {
    total = total + obj[key];
  });
  obj.total = total;
  return obj;
};

export const dayBefore = (day) => {
  if (typeof day === 'number') {
    return day * 24 * 60 * 60 * 1000;
  }
  return 0;
};

export const setDefaultDateForm = (form, type) => {
  form.dateTo = new Date();
  switch (type) {
    case STATISTICAL_OBJECT.YEAR:
      form.dateFrom = new Date(2023, 0, 1);
      break;
    case STATISTICAL_OBJECT.WEEK:
      form.dateFrom = dayjs()
        .startOf('week')
        .subtract(1, 'day')
        .format('YYYY-MM-DD HH:mm:ss');
      break;
    case STATISTICAL_OBJECT.MONTH:
      form.dateFrom = new Date(new Date().getFullYear(), 0);
      break;
    case STATISTICAL_OBJECT.QUARTER:
      form.dateFrom = new Date(new Date().getFullYear(), 0, 1);
      break;
    case STATISTICAL_OBJECT.DAY:
      form.dateFrom = new Date(
        new Date(Date.now() - dayBefore(7)).setHours(0, 0, 0)
      );
      break;
    case STATISTICAL_OBJECT.HOUR:
      form.dateFrom = new Date(new Date().setHours(0, 0, 0));
      break;
    default:
      form.dateFrom = new Date(Date.now() - dayBefore(7));
  }
};

export const setDefaultDateFormAttendance = (form, type) => {
  form.dateTo = new Date();
  switch (type) {
    case DATE_TYPE.YEAR:
      form.dateFrom =
        new Date().getFullYear() > 2024
          ? new Date().getFullYear() - 1
          : new Date(2024, 0, 1);
      break;
    case DATE_TYPE.WEEK:
      form.dateFrom = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      );
      break;
    case DATE_TYPE.MONTH:
      form.dateFrom = new Date(new Date().getFullYear(), 0);
      break;
    case DATE_TYPE.DAY:
      form.dateFrom = new Date(Date.now() - dayBefore(6));
      break;
    case DATE_TYPE.TIME:
      form.dateFrom = dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss');
      break;
    default:
      form.dateFrom = new Date(Date.now() - dayBefore(7));
  }
};

export const screenShotChart = (chartRef, fileName) => {
  const chartInstance = chartRef.getChartInstance();
  const url = chartInstance.getDataURL({
    type: 'png',
    pixelRatio: 2,
    backgroundColor: '#fff',
  });

  const link = document.createElement('a');
  link.href = url;
  link.download = `${fileName}.png`;
  link.click();
};
export const dragBoundFunc = (stageWidth, stageHeight, vertexRadius, pos) => {
  let x = pos.x;
  let y = pos.y;
  if (pos.x + vertexRadius > stageWidth) x = stageWidth;
  if (pos.x - vertexRadius < 0) x = 0;
  if (pos.y + vertexRadius > stageHeight) y = stageHeight;
  if (pos.y - vertexRadius < 0) y = 0;
  return { x, y };
};

export const minMax = (points) => {
  const result = points.reduce((acc, val) => {
    acc[0] = acc[0] === undefined || val < acc[0] ? val : acc[0];
    acc[1] = acc[1] === undefined || val > acc[1] ? val : acc[1];
    return acc;
  }, []);

  return [result[0] - 5, result[1] + 5];
};

export const getTextByArrStr = (
  value,
  array = [],
  label = 'label',
  val = 'value'
) => {
  if (!value || !array.length) return [];
  const valueArr = value.split(',');
  const s = array.filter((e) => valueArr.includes(e[val]));
  return s.map((e) => e[label]);
};
export const getNameProfile = (profile) => {
  return profile.lastName + ' ' + profile.firstName;
};
export const handleShowPassword = (id, listShowPassword) => {
  const index = listShowPassword.indexOf(id);
  if (index !== -1) {
    listShowPassword.splice(index, 1);
  } else {
    listShowPassword.push(id);
  }
};
export const handleHide = (password) => {
  return '*'.repeat(password.length);
};
export function useWindowHeight() {
  const windowHeight = ref(window.innerHeight);

  const updateWindowHeight = () => {
    windowHeight.value = window.innerHeight;
  };

  onMounted(() => {
    window.addEventListener('resize', updateWindowHeight);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', updateWindowHeight);
  });

  return { windowHeight };
}

export const formatTwoDigits = (num) => {
  return num.toString().padStart(2, '0');
};

export const replaceUndefinedWithNull = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined || obj[key] === null || obj[key] === '') {
      obj[key] = null;
    }
  });
};

export const formatSecondDate = (value) => {
  const { startTime, finishTime, startBreakTime, finishBreakTime } = value;

  if (startTime && finishTime) {
    const start = new Date(startTime).getTime();
    const finish = new Date(finishTime).getTime();
    let diff = finish - start;

    if (startBreakTime && finishBreakTime) {
      const breakStart = new Date(startBreakTime).getTime();
      const breakFinish = new Date(finishBreakTime).getTime();
      diff -= breakFinish - breakStart;
    }

    if (diff < 0) return '00:00:00';
    const totalSeconds = diff / 1000;
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);

    return [
      String(hours).padStart(2, '0'),
      String(minutes).padStart(2, '0'),
      String(seconds).padStart(2, '0'),
    ].join(':');
  }

  return '00:00:00';
};
// hàm dùng để lấy tất cả các ngày có thứ thuộc [days] trong theo chu kỳ tuần
export function getWeekends(year, days, timeLoop, dayEnds) {
  const weekends = [];
  const startDate = new Date(year, 0, 1);
  const endDate = new Date(year, 11, 31);
  const finalEndDate = dayEnds ? dayjs(dayEnds) : dayjs(endDate);
  let currentDate = startDate;

  while (currentDate <= endDate) {
    const day = currentDate.getDay();

    if (days.includes(day)) {
      weekends.push(dayjs(currentDate).format('YYYY-MM-DD'));
    }

    currentDate = getNextDate(currentDate);
  }

  const filteredWeekends = weekends.filter(
    (date) =>
      dayjs(date).isBefore(finalEndDate, 'day') ||
      dayjs(date).isSame(finalEndDate, 'day')
  );
  const result = [];
  for (let index = 0; index < filteredWeekends.length; index += timeLoop) {
    result.push(filteredWeekends[index]);
  }
  return result;
}

function getNextDate(currentDate) {
  let nextDate = new Date(currentDate);
  nextDate.setDate(currentDate.getDate() + 1);
  return nextDate;
}

// hàm dùng để lấy tất cả các ngày có thứ thuộc [days] theo chu kỳ tháng
export function getDaysInMonth(year, days, timeLoop, dayEnds) {
  const List = [];
  const endDate = dayEnds ? dayjs(dayEnds) : dayjs(new Date(year, 11, 31));

  const monthsToProcess = Array.from({ length: 12 }, (_, i) => i).filter(
    (month) => month % timeLoop === 0
  );

  for (const month of monthsToProcess) {
    const startDate = dayjs(new Date(year, month, 1));
    const monthEndDate = dayjs(new Date(year, month + 1, 0));
    for (
      let date = startDate.add(0, 'day');
      date <= monthEndDate;
      date = date.add(1, 'day')
    ) {
      if (date.isAfter(endDate)) break;
      addDateToList(date, List, days);
    }
  }

  return List;
}

function addDateToList(date, List, days) {
  if (days.includes(date.day())) {
    const formattedDate = date.format('YYYY-MM-DD');
    if (!List.includes(formattedDate)) {
      List.push(formattedDate);
    }
  }
}

export function safeParse(jsonString, defaultValue = []) {
  try {
    return JSON.parse(jsonString) || defaultValue;
  } catch (error) {
    console.error('Failed to parse JSON:', error);
    return defaultValue;
  }
}
export {
  ellipsis,
  encryptData,
  hashData,
  formatDate,
  formatSource,
  checkResponse,
  forEachNested,
  greaterThanCurrent,
  lessThanCurrent,
  getText,
  censorNumber,
  roundNumber,
  flatten,
  randomChar,
  addToList,
  addEdge,
  convertDataFlow,
  slugify,
  checkResponseAsync,
  ellipsisText,
  getTextCustom,
  trimValueObj,
  checkHasPermission,
  mapAndGetTotal,
};
