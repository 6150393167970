<template>
  <a-config-provider :locale="locale">
    <!-- <img src="require('/src/static/img/Logo_Dark.png')" alt=""/> -->
    <div v-if="isLoading" class="spin md:h-full" style="height: 100vh">
      <a-spin />
    </div>
    <ThemeProvider
      class="h-auto md:h-full"
      v-else
      :theme="{
        ...theme,
        rtl,
        topMenu,
        darkMode,
      }"
    >
      <Suspense class="h-full">
        <template #default>
          <router-view></router-view>
        </template>
        <template #fallback>
          <div class="spin">
            <a-spin />
          </div>
        </template>
      </Suspense>
    </ThemeProvider>
  </a-config-provider>
  <cds-modal
    :title="t(`notification.${viewNotification.eventCode}`).toUpperCase()"
    :visible="visible"
    :wrap-class-name="'notification-modal'"
    :mask-style="{ zIndex: 1050 }"
    hide-footer
    width="960px"
    @onCancel="visible = false"
  >
    <NotificationWarningModal :detail="viewNotification" />
  </cds-modal>
</template>
<script>
import { ThemeProvider } from 'vue3-styled-components';
import { theme } from './config/theme/themeVariables';
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
  watch,
  onUnmounted,
  inject,
} from 'vue';
import { useStore } from 'vuex';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import 'dayjs/locale/en';
import vi_VN from 'ant-design-vue/es/locale/vi_VN';
import en_US from 'ant-design-vue/es/locale/en_US';
import { notification } from 'ant-design-vue';
import { formatDate } from '@/util/common-utils';
import { useRoute } from 'vue-router';
import { decryptData } from '@/dataService/dataService';
import {
  EVENT_STATUS,
  PROFILE_TYPE_COLOR,
  PERCENT_IMPORT_PROFILE,
  SOCKET_TYPE,
} from '@/util/common-constant';
import { useI18n } from 'vue-i18n';
import NotificationWarningModal from '@/components/notification-warning-modal/NotificationWarningModal.vue';
import { getNameProfile } from '@/util/common-utils';
import axios from 'axios';

export default defineComponent({
  name: 'App',
  components: {
    NotificationWarningModal,
    ThemeProvider,
  },
  setup() {
    // eslint-disable-next-line no-unused-vars
    const { t, setLocaleMessage } = useI18n();
    const eventBus = inject('eventBus');
    const { state, dispatch } = useStore();
    const route = useRoute();
    const languageCode = localStorage.getItem('locale');
    dayjs.locale(languageCode);
    const rtl = computed(() => state.themeLayout.rtlData);
    const isLoading = ref(true);
    const darkMode = computed(() => state.themeLayout.data);
    const topMenu = computed(() => state.themeLayout.topMenu);
    const isFbAuthLoading = computed(() => state.firebase.loading);
    const viewNotification = computed(() => state.auth.viewNotification);
    const notifySetting = computed(
      () => state.alertConfiguration.notifySetting
    );
    const languageUrl = computed(() => state.auth.languageUrl);
    const file = ref(null);
    const connection = ref(null);
    const connectionUploadFile = ref(null);
    onMounted(async () => {
      eventBus.on('profile-management-import-profile', () => {
        connectSocketUploadFile();
      });
      if (localStorage.getItem(PERCENT_IMPORT_PROFILE)) {
        dispatch(
          'profileManagement/setProcess',
          localStorage.getItem(PERCENT_IMPORT_PROFILE)
        );
      }

      try {
        if (route.path === '/manager/private-policy') return;
        await dispatch('getUserInfo');
        const lang = languageCode ? languageCode : 'vi';
        await dispatch('getLanguage', lang);
        await dispatch('alertConfiguration/getNotifySetting');
        const res = await axios.get(languageUrl.value);
        setLocaleMessage(lang, res.data);
      } finally {
        isLoading.value = false;
      }
      handleSocket();
    });
    onUnmounted(() => {
      eventBus.off('profile-management-import-profile');
    });
    const handleSocket = () => {
      connection.value = new WebSocket(
        `${decryptData(window.VUE_APP_API_ENDPOINT)}/socket?access_token=${
          state.auth.tokenInfo.accessToken
        }`
      );
      connection.value.onmessage = (event) => {
        const res = JSON.parse(event.data);
        if (res.type === SOCKET_TYPE.NOTIFY) {
          dispatch('notification/setCurrentNotification', res.data);
          dispatch('addToNotification', res.data);
          if (notifySetting.value && notifySetting.value.sound) {
            playSound();
          }
          if (notifySetting.value && notifySetting.value.popup) {
            onNotify();
          }
        } else {
          dispatch('event/addEventSocket', res);
        }
      };
      connection.value.ondisconnect = async () => {
        await dispatch('getUserInfo');
        connection.value = new WebSocket(
          `${decryptData(window.VUE_APP_API_ENDPOINT)}/socket?access_token=${
            state.auth.tokenInfo.accessToken
          }`
        );
      };
    };

    watch(
      () => state.auth.tokenInfo,
      () => {
        if (connection.value === null) {
          handleSocket();
        }
      },
      { deep: true }
    );

    const locale = computed(() => {
      switch (languageCode) {
        case 'en':
          return en_US;
        default:
          return vi_VN;
      }
    });
    const app = getCurrentInstance();
    const visible = ref(false);
    const playSound = () => {
      const soundName =
        notifySetting.value && notifySetting.value.sound_name
          ? notifySetting.value.sound_name
          : 'bell';
      const sound = new Audio(`/assets/alarm-tone/${soundName}.mp3`);
      sound.play();
    };
    const onNotify = () => {
      const currentNotification =
        app.appContext.provides.store.getters[
          'notification/currentNotification'
        ];
      const notifySetting =
        app.appContext.provides.store.getters[
          'alertConfiguration/notifySetting'
        ];
      const i18n = app.appContext.config.globalProperties.$i18n;
      notification.open({
        message: (
          <div class="flex gap-[6px]">
            <img
              src="/assets/WarningCircle.svg"
              alt="WarningCircle"
              class="w-[24px] h-[24px] object-cover shrink-0 rounded-[4px] my-auto"
            />
            <div class="font-bold text-[16px]">
              {i18n.global.t(`notification.${currentNotification.eventCode}`)}
            </div>
          </div>
        ),
        description: (
          <div class="flex gap-[24px]">
            <img
              src={currentNotification.imageUrl}
              alt={currentNotification.imageUrl}
              class="w-[100px] h-[84px] object-cover shrink-0 rounded-[4px] my-auto"
            />
            <div class="flex flex-col gap-[6px]">
              <div class="font-bold text-[16px]">
                {currentNotification.lastName && currentNotification.firstName
                  ? getNameProfile(currentNotification)
                  : i18n.global.t('notification.undefined')}
              </div>
              <div class="flex gap-[6px]">
                <div
                  class={`rounded-[22px] px-[12px] text-[10px] leading-[18px] ${
                    PROFILE_TYPE_COLOR[currentNotification.profileType].color
                  }`}
                >
                  <span class="m-0 whitespace-nowrap">
                    {i18n.global.t(
                      PROFILE_TYPE_COLOR[currentNotification.profileType].text
                    )}
                  </span>
                </div>
                <div
                  class={`rounded-[22px] px-[12px] text-[10px] leading-[18px] border-[1px] ${
                    EVENT_STATUS[currentNotification.eventType].color
                  }`}
                >
                  <span class="m-0 whitespace-nowrap">
                    {i18n.global.t(
                      EVENT_STATUS[currentNotification.eventType].text
                    )}
                  </span>
                </div>
              </div>
              <div class="text-[12px] font-medium">
                {formatDate(
                  currentNotification.createdAt,
                  'dd/MM/yyyy - HH:mm:ss'
                )}
              </div>
              <div
                class="text-[12px] font-medium text-hrm-lam cursor-pointer w-[fit-content]"
                onClick={() => onViewDetail(currentNotification)}
              >
                {i18n.global.t('common.detail')}
              </div>
            </div>
          </div>
        ),
        placement:
          notifySetting && notifySetting.location_popup
            ? notifySetting.location_popup
            : 'bottomRight',
        class: 'warning-notification',
        duration: 5,
      });
    };
    const onViewDetail = (data) => {
      visible.value = true;
      dispatch('setViewNotification', data);
    };
    const connectSocketUploadFile = () => {
      connectionUploadFile.value = new WebSocket(
        `${decryptData(
          window.VUE_APP_API_ENDPOINT
        )}/hrm-system/status-bar?access_token=${
          state.auth.tokenInfo.accessToken
        }`
      );
      connectionUploadFile.value.onmessage = (event) => {
        const res = JSON.parse(event.data);
        let percent = Math.round((res.processed / res.total) * 100);
        dispatch('profileManagement/setProcess', percent);
        localStorage.setItem(PERCENT_IMPORT_PROFILE, percent);
        if (res.completed) {
          notifyUpload(res);
          if (Number(res.result.fail) > 0) {
            fetch(res.result.url)
              .then((response) => response.blob())
              .then((blob) => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(new Blob([blob]));
                link.setAttribute('download', `profile-err-${Date.now()}.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              })
              .catch(console.error);
          }
          connectionUploadFile.value.close();
        }
      };
    };
    const notifyUpload = (data) => {
      notification.open({
        message: (
          <div class="font-bold text-[16px]">
            {t('notification.import-success')}
          </div>
        ),
        description: (
          <div>
            <div class="flex gap-[16px] items-center">
              <img
                src="/assets/success.svg"
                alt="success"
                width="20"
                height="20"
              />
              <span class="text-[12px] font-medium text-hrm-gray800">
                {t('notification.record-success')}
                <span class="text-[12px] font-bold">
                  {' '}
                  {data.result.success}
                </span>
              </span>
            </div>
            <div class="flex gap-[16px] mt-[8px] items-center">
              <img src="/assets/error.svg" alt="error" width="20" height="20" />
              <span class="text-[12px] font-medium text-hrm-gray800">
                {t('notification.record-error')}
                <span class="text-[12px] font-bold"> {data.result.fail} </span>
                {t('notification.check-file')}
              </span>
            </div>
          </div>
        ),
        placement: 'bottomRight',
        class: 'import-notification',
        duration: 5,
      });
    };

    return {
      theme,
      rtl,
      darkMode,
      topMenu,
      isLoading,
      isFbAuthLoading,
      locale,
      file,
      onNotify,
      visible,
      t,
      viewNotification,
    };
  },
});
</script>
<style>
.warning-notification {
  padding: 0;

  .ant-notification-notice-message {
    @apply bg-hrm-do700 py-[8px] px-[16px] text-hrm-white m-0;
  }

  .ant-notification-notice-description {
    @apply p-[16px];
  }

  .ant-notification-notice-close {
    top: 6px;
    right: 16px;
  }
}
.import-notification {
  padding: 0;

  .ant-notification-notice-message {
    @apply bg-hrm-main1 py-[8px] px-[16px] text-hrm-white m-0 font-bold;
  }

  .ant-notification-notice-description {
    @apply p-[16px];
  }

  .ant-notification-notice-close {
    top: 6px;
    right: 16px;
  }
}

.notification-modal {
  z-index: 1050;

  .ant-modal-header {
    @apply bg-hrm-do700;
  }

  .ant-modal {
    top: 50px;
  }
}
</style>
